@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Marcellus&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.font-sans {
  font-family: "Poppins", sans-serif;
}
.font-serif {
  font-family: "Marcellus", serif;
}

body {
  @apply bg-white font-sans font-normal text-neutral-800;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}
.text-shad-wh {
  text-shadow: 0px 3px 1px 1px rgba(255, 255, 0255, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium tracking-wide;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
body main p {
  @apply mb-4 text-lg font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Marcellus", serif;
  line-height: 1.1em !important;
  letter-spacing: 0;
}
h1 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}

.page-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.page-heading:before {
  @apply bg-fixed absolute bg-gradient-to-l to-blue-900 via-blue-800/70 from-transparent bg-opacity-15 bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.slider-sec:before {
  @apply bg-fixed absolute bottom-0 bg-gradient-to-l to-black via-black/20 from-transparent bg-opacity-5 left-0 right-0 top-0 w-full overflow-hidden;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-indigo-950 bg-opacity-90 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.blue-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.blue-tint:before {
  @apply bg-fixed absolute bottom-0 bg-blue-900 bg-opacity-80 left-0 right-0 top-0 w-full overflow-hidden;
}

.black-tint {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.black-tint:before {
  @apply bg-fixed absolute bottom-0 bg-black left-0 right-0 top-0 w-full overflow-hidden;
}

.column-bg {
  @apply bg-cover;
}
.bg-value {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}

.heading-vert {
  writing-mode: vertical-rl;
  rotate: 180deg;
}

.btn-theme {
  @apply bg-transparent hover:bg-red-600 border-2 border-white text-white font-serif font-bold uppercase;
}
.btn-white {
  @apply bg-white hover:bg-transparent border-2 border-white text-black hover:text-white font-serif font-bold uppercase;
}
.btn-red {
  @apply bg-red-600 hover:bg-red-700 border-2 border-red-600 text-white font-serif font-bold uppercase;
}
.btn-orange {
  @apply bg-orange-600 hover:bg-orange-700 border-2 border-orange-600 text-white text-sm font-bold;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
